import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Layout, SEO } from 'components';
import { graphql } from 'gatsby';
import { Stack, Container, media, Box } from '@tymate/margaret';
import { PageTitle, Heading, fontStyles } from 'ui';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { GATSBY_API_KEY_GOOGLE_MAP } from '../constants';
import AppointmentSection from '../components/AppointmentSection';
import logo from 'images/logo.svg';

const GoogleMap = styled(Map)`
  width: 100%;
  height: 100%;
`;

const Address = styled.p`
  ${fontStyles.body};
  margin: 0;
  color: ${({ theme }) => theme.textLight};
`;

const MapWrapper = styled(Box)`
  position: relative;
  height: 25em;
  max-height: 100vh;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  img {
    width: 100%;
    max-width: 300px;
  }
`;

const LawFirmGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${({ theme }) => theme.spacing(2)};
  ${media.tablet`
    grid-template-columns: repeat(2,1fr);
    grid-gap: ${({ theme }) => theme.spacing(4)}
  `};
`;

const MapComponent = GoogleApiWrapper({
  apiKey: GATSBY_API_KEY_GOOGLE_MAP,
  language: 'fr',
})(({ google, pois }) => {
  const mapRef = useRef();

  const handleResetBounds = () => {
    if (Boolean(mapRef?.current?.map?.fitBounds)) {
      const bounds = new google.maps.LatLngBounds();

      pois.forEach(({ location }) =>
        bounds.extend({
          lat: location?.latitude,
          lng: location?.longitude,
        }),
      );

      mapRef.current.map.fitBounds(bounds);
    }
  };

  useEffect(() => {
    handleResetBounds();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <MapWrapper marginBottom={2}>
        <GoogleMap
          ref={mapRef}
          google={google}
          zoom={8}
          fullscreenControl={false}
          streetViewControl={false}
          mapTypeControl={false}
          scrollwheel={false}
          onReady={handleResetBounds}
        >
          {(pois || []).map(({ location, city }, index) => (
            <Marker
              key={index}
              position={{
                lat: location?.latitude,
                lng: location?.longitude,
              }}
              name={city.name}
            />
          ))}
        </GoogleMap>
      </MapWrapper>
    </>
  );
});

const ContactPage = ({ data }) => (
  <Layout>
    <SEO
      title={data?.datoCmsContactPage?.seo?.title}
      description={data?.datoCmsContactPage?.seo?.description}
    />
    <Container variant="main">
      <PageTitle>{data?.datoCmsContactPage?.title}</PageTitle>
    </Container>

    <MapComponent pois={data?.allDatoCmsLawFirm?.nodes} />

    <Container variant="main">
      <LawFirmGrid>
        {data?.allDatoCmsLawFirm?.nodes.map(
          ({
            address,
            detailsAddress,
            zipCode,
            city,
            phoneNumber,
            faxNumber,
          }) => (
            <div>
              <Heading>{city}</Heading>
              <Stack
                direction={{ default: 'column', tablet: 'row' }}
                gutterSize={{ default: 1, tablet: 2, desktop: 4 }}
              >
                <div>
                  <Address>{address}</Address>
                  {detailsAddress && <Address>{detailsAddress}</Address>}
                  <Address>
                    {zipCode} {city}
                  </Address>
                </div>
                <div>
                  <Address>Téléphone: {phoneNumber}</Address>
                  <Address>Fax: {faxNumber}</Address>
                </div>
              </Stack>
            </div>
          ),
        )}
      </LawFirmGrid>
    </Container>
    <AppointmentSection variant="contactPage" />
    <Logo>
      <img src={logo} alt="essentiel a" />
    </Logo>
  </Layout>
);

export const query = graphql`
  query {
    datoCmsContactPage {
      title
      seo {
        title
        description
      }
    }
    allDatoCmsLawFirm {
      nodes {
        address
        detailsAddress
        zipCode
        city
        phoneNumber
        faxNumber
        comment
        location {
          latitude
          longitude
        }
      }
    }
  }
`;

export default ContactPage;
